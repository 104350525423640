<template>
    <main class="mt-4 border-top">

        <div id="mainCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="5000">
                <img src="../assets/banner/banner1.png" class="d-block w-100" alt="Comece sua carreira como desenvolvedor">
              </div>
              <div class="carousel-item" data-bs-interval="5000">
                <img src="../assets/banner/banner2.png" class="d-block w-100" alt="Leia nossos Artigos, E-books e assista as vídeo aulas e cursos grátis! ">
              </div>
              <div class="carousel-item" data-bs-interval="5000">
                <img src="../assets/banner/banner1.png" class="d-block w-100" alt="Leia nossos Artigos, E-books e assista as vídeo aulas e cursos grátis! ">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#mainCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#mainCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

        <h2 class="global-font-style mt-4 mb-4">Cursos disponíveis</h2>
        <div class="row">
            <div class="col-sm-4 mb-4 " v-for="item in courses" :key="item.id">
                <a class="link" :href="item.link" target="_blank">
                    <div class="card shadow h-100">
                        <img class="card-img-top" :src="item.img" :alt="item.title" />
                        <div class="card-body">
                            <h6 class="card-title global-font-style">{{ item.title }}</h6>
                            <div><small class="card-text">{{ item.text }}</small></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <h2 class="global-font-style mt-4 mb-4">Artigos</h2>
        <div class="row">
            <div class="col-sm-4 mb-4" v-for="item in article" :key="item.id">
                <a class="link" :href="item.link" target="_blank">
                    <div class="card shadow h-100">
                        <img class="card-img-top" :src="item.img" :alt="item.title" />
                        <div class="card-body">
                            <h6 class="card-title global-font-style">{{ item.title }}</h6>
                            <div><small class="card-text">{{ item.text }}</small></div>                     
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        data() {
            return {
                article: [
                {
                        id: 3,
                        img: require(`@/assets/artigos/tesseract.jpg`),
                        title: 'Tesseract-OCR e Python',
                        text: 'Reconhecimento de caracteres em imagens',
                        button: 'Ler',
                        link: 'https://www.linkedin.com/pulse/reconhecimento-de-caracteres-em-imagens-com-e-python-medeiros/'
                    },
                    {
                        id: 4,
                        img: require(`@/assets/artigos/processamento-imagens.jpg`),
                        title: 'Pré-processamento de imagens com Python',
                        text: 'Artigo sobre pré-processamento de imagens com Python.',
                        button: 'Ler',
                        link: 'https://www.linkedin.com/pulse/pr%C3%A9-processamento-de-imagens-genilson-medeiros/'
                    }
                ],
                courses: [
                    {
                        id: 1,
                        img: require(`@/assets/cursos/microservices_hotmart.png`),
                        title: 'Microservices do 0 com Spring Cloud, Netflix Eureka e mais',
                        text: 'Desenvolva microsserviços de uma forma profissional',
                        button: 'Comprar',
                        link: 'https://gm2.dev.br/campaign/microservices/'
                    },
                    {
                        id: 2,
                        img: require(`@/assets/cursos/springboot.jpg`),
                        title: 'Spring Boot Prático: JPA, RESTFul, Security, JWT e mais',
                        text: 'Curso completo de Spring Boot',
                        button: 'Comprar',
                        link: 'https://www.udemy.com/course/spring-boot-pratico-jpa-restful-security-jwt-e-mais/?referralCode=3CDD4D1877D87BF5BF7C'
                    },   
                    {
                        id: 3,
                        img: require(`@/assets/cursos/nodejs.jpg`),
                        title: 'NodeJS: Saindo do zero com vários projetos',
                        text: 'Entre no mundo do desenvolvimento para backend com o nodejs',
                        button: 'Comprar',
                        link: 'https://pay.hotmart.com/L95104912N?bid=1724443660829'
                    },  
                    {
                        id: 4,
                        img: require(`@/assets/em-breve.png`),
                        title: 'Docker: O guia definitivo',
                        text: 'Tudo que você precisa para aprender docker',
                        button: 'Comprar',
                        link: ''
                    }
                ]
            }
        },
    }
</script>
<style>
.card-text{
    color: black;
}

.link{
    text-decoration: none;
}

.link:hover {
    font-weight: 600;

}

.card-img-top{
    height: 25vh;
}

.carousel-item img{
    border-radius: 10px;
}

.photo {
    width: 160px;
    border: 6px solid #343654;
}
</style>