<template>
  <header>
    <div class="container">
      <div class="d-flex justify-content-center">
        <a class="me-3 text-dark py-2 text-decoration-none" href="https://www.instagram.com/gm2.dev.br/"
          target="_blank"><i class="bi bi-instagram"></i></a>
        <a class="me-3 text-dark py-2 text-decoration-none" href="https://github.com/genilsonmm" target="_blank"><i
            class="bi bi-github"></i></a>
        <a class="me-3 text-dark py-2 text-decoration-none" href="https://www.linkedin.com/in/genilsonmedeiros/"
          target="_blank"><i class="bi bi-linkedin"></i></a>
        <a class="me-3 text-dark py-2 text-decoration-none" href="https://www.youtube.com/user/genilsonmm"
          target="_blank"><i class="bi bi-youtube"></i></a>
      </div>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <a href="/" class="d-flex flex-column text-dark align-items-center text-decoration-none">
            <img src="@/assets/logo.png" width="120" alt="logo" />
            <span class="title-logo global-font-style">Treinamento e consultoria</span>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <div class="nav-item dropdown global-font-style">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Disciplinas
                </a>
                <ul class="dropdown-menu">
                  <li v-for="subject in subjects" :key="subject.id"><router-link :to="{ name: 'Subjects', params: { subjectId: subject.id} }" class="dropdown-item">{{ subject.name }}</router-link></li>
                </ul>
              </div>
              <a class="global-font-style me-3 py-2 text-decoration-none" target="_blank"
                href="https://www.linkedin.com/pulse/reconhecimento-de-caracteres-em-imagens-com-e-python-medeiros/">Artigos</a>
              <a class="global-font-style me-3 py-2 text-decoration-none" target="_blank"
                href="https://www.udemy.com/course/spring-boot-pratico-jpa-restful-security-jwt-e-mais/?referralCode=3CDD4D1877D87BF5BF7C">Cursos</a>
                <router-link to="/videos/1" class="global-font-style me-3 py-2 text-decoration-none">Vídeos</router-link>
              <a class="global-font-style me-3 py-2 text-decoration-none" target="_blank"
                href="https://linktr.ee/genilsonmm">Links</a>
              <router-link to="/about" class="global-font-style me-3 py-2 text-decoration-none">Genilson</router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>
<script setup>
  import {ref } from 'vue';
  import subjectService from '@/services/subjectService.js'

  const subjects = ref(subjectService.getSubjects())

</script>
<style scoped>
  .title-logo {
    font-size: 0.9rem;
  }
</style>